// Generated by Framer (e1a5221)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pHKm41qr5"];

const serializationHash = "framer-3iv7T"

const variantClassNames = {pHKm41qr5: "framer-v-4ya0nv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pHKm41qr5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-4ya0nv", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"pHKm41qr5"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-ydcstr"} data-framer-name={"Text input container"} fill={"rgba(0,0,0,1)"} intrinsicHeight={152} intrinsicWidth={939} layoutDependency={layoutDependency} layoutId={"kgn0orL21"} svg={"<svg width=\"939\" height=\"152\" viewBox=\"0 0 939 152\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<g clip-path=\"url(#clip0_2_581)\">\n<path d=\"M564 71.192L752 115H940V152H2.43187e-05V1H188L376 71.192H564Z\" fill=\"url(#paint0_linear_2_581)\"/>\n<path d=\"M188.577 0H2.43187e-05V2H187.423L375.423 72.192H563.619L751.619 116H940V114H752.381L564.381 70.192H376.577L188.577 0Z\" fill=\"#A6ACC2\"/>\n</g>\n<defs>\n<linearGradient id=\"paint0_linear_2_581\" x1=\"472.338\" y1=\"1\" x2=\"472.218\" y2=\"152.004\" gradientUnits=\"userSpaceOnUse\">\n<stop stop-color=\"#EF443B\" stop-opacity=\"0.64\"/>\n<stop offset=\"1\" stop-color=\"white\" stop-opacity=\"0\"/>\n</linearGradient>\n<clipPath id=\"clip0_2_581\">\n<rect width=\"940\" height=\"152\" fill=\"white\" transform=\"matrix(-1 0 0 1 940 0)\"/>\n</clipPath>\n</defs>\n</svg>\n"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3iv7T.framer-qhw0r7, .framer-3iv7T .framer-qhw0r7 { display: block; }", ".framer-3iv7T.framer-4ya0nv { height: 152px; overflow: visible; position: relative; width: 939px; }", ".framer-3iv7T .framer-ydcstr { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 152
 * @framerIntrinsicWidth 939
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerlWA4hQag3: React.ComponentType<Props> = withCSS(Component, css, "framer-3iv7T") as typeof Component;
export default FramerlWA4hQag3;

FramerlWA4hQag3.displayName = "Text input container";

FramerlWA4hQag3.defaultProps = {height: 152, width: 939};

addFonts(FramerlWA4hQag3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})